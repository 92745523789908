.resizable {
  resize: horizontal;
  overflow: hidden;
}

.left {
  text-align: left;
}
.small {
  width: 3em;
}

.tableWrap {
  width: 100%;
  min-height: 725px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  margin-bottom: 0.5em;
  table-layout: fixed;

  thead tr th {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;

    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.9em;
    position: relative;
    overflow: hidden;

    &:first-child {
      border-top-left-radius: 5px;
      border-left: 1px solid #d9d9d9;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-right: 1px solid #d9d9d9;
    }

    .headerlabel {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  thead tr th .resizer {
    position: absolute;
    right: 1em;
    height: 65%;
    width: 3px;
    border-right: 1px solid #d8d8d8;
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }

  tbody {
    padding: 0.5em;
    cursor: pointer;
  }

  tbody tr {
    background-color: #f9fafd;
  }

  tbody tr.selected {
    background-color: rgba(81, 31, 255, 0.13);
    font-weight: 600; /* semibold */
  }

  tbody tr.resolved {
    color: #b3b3b3;
  }

  tbody tr.alert {
    font-weight: bold;

    .InlineChip :global(.MuiChip-label) {
      font-size: 95%;
    }
  }

  tbody tr.resolved.selected {
    color: #908db0b6;
  }

  tbody tr td {
    border-bottom: 1px solid #cacaca;

    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0.25em;
    padding-left: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:first-child {
      border-left: 1px solid #d9d9d9;
    }

    &:last-child {
      border-right: 1px solid #d9d9d9;
    }
  }

  tbody tr:last-of-type td {
    &:first-child {
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-bottom-right-radius: 5px;
    }
  }
}

.emptyMessage {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #888;
}

.pagination {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
}

.pagination .rowcounts {
  font-size: 0.9em;
  color: #777;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.pagination .pagesize {
  font-size: 0.9em;
}
